import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  records;
  displayedColumns: string[] = ['position', 'name', 'email', 'phone', 'city', 'englishLevel', 'source', 'file'];


  constructor(
    private fs: FirestoreService,
    public storage: StorageService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
     this.records = this.fs.readRecords();
  }

  logout() {
    this.authService.logout().then( res => this.router.navigateByUrl('login'));
  }

}
