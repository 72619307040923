import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';

@Pipe({
  name: 'fileDownload'
})
export class FileDownloadPipe implements PipeTransform {

  profileUrl: Observable<string | null>;

  constructor(private storage: AngularFireStorage) {}

  transform(value: string) {
    if ( value !== '') {
      return this.storage.ref(value).getDownloadURL();
    }
    return '#';
  }

}
