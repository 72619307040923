import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'academy';
  ngOnInit(): void {
    $(document).ready(() => {
      // const altura = $('.menu').offset().top;

      // $('a.scroll-link').click((e) => {

      //   e.preventDefault();
      //   const id = $(this).attr('href');
      //   $('body,html').animate({
      //     scrollTop: $(id).offset().top - 20
      //   }, 750);
      // });


      // $(window).on('scroll', () => {
      //   if ($(window).scrollTop() > altura) {
      //     $('.menu').addClass('menu-fixed');
      // } else {
      //     $('.menu').removeClass('menu-fixed');
      // }
      // });
    });
  }
}
