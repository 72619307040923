import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { FirestoreService } from '../../services/firestore.service';
import { StorageService } from '../../services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  cities = [
    {name: 'Bogotá', value: 'BOG'},
    {name: 'Cali', value: 'CLO'},
  ];

  profileForm = new FormGroup({
    fullName: new FormControl('', Validators.required),
    age: new FormControl('', [Validators.required]),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    englishLevel: new FormControl('', Validators.required),
    source: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    legals: new FormControl('', Validators.required),
    file: new FormControl(''),
  });

  constructor(
    private firestoreService: FirestoreService,
    private storageService: StorageService,
    private analytics: AngularFireAnalytics,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.hideLoader();
  }

  async onSubmit() {
    this.showLoader();
    const profile = this.profileForm.value;
    profile.ts = new Date().getTime();
    if (this.profileForm.controls.file.value !== '') {
      const files = (document.getElementById('file') as HTMLInputElement).files;
      await this.storageService.uploadFile(files).then( res => profile.file = res.metadata.fullPath );
    }

    this.firestoreService.saveRecord(profile).then((res) => {
      this.analytics.logEvent('form_submit', {
        english_level: profile.englishLevel,
        city: profile.city,
        source: profile.source,
      }).then( () => {
        return this.snackBar.open('Thanks for filling the form and sharing your information with us. We will reach ASAP.', '', {
          duration: 5000,
          verticalPosition: 'top'
        });
      } ).then(() => {
        this.hideLoader();
        this.profileForm.markAsPristine();
        this.profileForm.reset();
        this.profileForm.markAsUntouched();
      } );
    } ).catch(err => console.log(err));
  }

  showLoader(): void {
    document.getElementById('preloader').style.display = 'block';
  }

  hideLoader(): void {
    document.getElementById('preloader').style.display = 'none';
  }

}
