import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.authService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value).then(( res ) => {
      console.log( res );
      if (res?.user?.email === this.loginForm.controls.email.value) {
        this.router.navigateByUrl('results');
      }
    }).catch( err => {
      return this.snackBar.open(err, 'Retry', {
        duration: 10000,
        verticalPosition: 'top'
      });
    });
  }

}
