import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private storage: AngularFireStorage) { }

  async uploadFile(files)  {
    console.log( files );
    const file = files[0];
    const now = new Date().getTime();
    const filePath = `academy/attachments/${now}_${file.name}`;
    return await this.storage.upload(filePath, file);
  }

  async getDownloadUrl(path: string ) {
    return this.storage.ref(path).getDownloadURL();
  }
}
