<mat-toolbar color="primary">

    <mat-toolbar-row>
        <span>Academy Form Results</span>
        <span class="spacer"></span>
        <button mat-icon-button aria-label="Sign out" (click)="logout()">
            <mat-icon class="icon" aria-hidden="false" aria-label="Sign out">login</mat-icon>
          </button>

    </mat-toolbar-row>
</mat-toolbar>

<mat-table [dataSource]="records" class="mat-elevation-z0">
    <!-- Position Column -->
    <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.ts | date }} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fullName}} </mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> E-mail </mat-header-cell>
        <mat-cell *matCellDef="let element"> <a [href]="'mailto:' + element.email">{{element.email}}</a> </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="englishLevel">
        <mat-header-cell *matHeaderCellDef> Inglés </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.englishLevel}} </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef> Edad </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.age}} años</mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef> Ciudad </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.city?.name}} </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef> Teléfono </mat-header-cell>
        <mat-cell *matCellDef="let element"> <a [href]="'tel:' + element.phone">{{element.phone}}</a> </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef> Fuente </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.source}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="file">
        <mat-header-cell *matHeaderCellDef> CV </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <a target="_blank" [href]="(element.file | fileDownload | async )" *ngIf="element.file" mat-icon-button color="accent" aria-label="Example icon button with a home icon">
                <mat-icon>get_app</mat-icon>
            </a>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>