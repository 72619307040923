<footer>
    <div class="footer-wrapper">

        <div class="footer-copyright">
            <figure class="footer-copyright-img">
                <img src="assets/img/logo-pmx-academy-2.png" alt="Logo PMX Academy" class="img-fluid">
            </figure>
            <p class="footer-copyright-subtitle">all rights reserved</p>
        </div>
    </div>
</footer>