import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private Auth: AngularFireAuth
  ) { }

  login(email, password) {
    return this.Auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.Auth.signOut();
  }
}
