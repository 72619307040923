<app-header></app-header>




<div id="preloader">
    <div id="loader"></div>
</div>

<div class="container-banner" id="home">

    <div class="container-titulo">
        <h1 class="animated fadeInLeft">
            Welcome to PMX Academy
        </h1>
    </div>
    <div class="imagen-principal">
        <img src="assets/img/banner-desktop.jpg" class="desktop" alt="" title="">
        <img src="assets/img/banner-mobile.jpg" class="mobile" alt="" title="">
    </div>
    <div class="container-texto">
        <h2>
            <span>BI</span>
            <span>LIN</span>
            <span>GUAL</span>
        </h2>
        <h3>
            Catch the great opportunities.
            <br>This is your chance to learn & work in Digital Marketing.
            <br>A powerful path to digital transformation

        </h3>
    </div>

    <div class="box-video">
        <video id="VideoInstructivo" autoplay="true" controls poster="assets/img/covervideo.png" loop="true">
          <source src="assets/img/Instructivo_PrecisionAcademy.mp4" type="video/mp4">
          <source src="assets/img/Instructivo_PrecisionAcademy.ogg" type="video/ogg">
          Your browser does not support HTML5 video.
        </video>
    </div>


    <a class="scroll-link" href="#about">
        <i class="fas fa-angle-up"></i>
        <svg class="mouse" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 76 130" preserveAspectRatio="xMidYMid meet">
            <g fill="none" fill-rule="evenodd">
                <rect width="70" height="118" x="1.5" y="1.5" stroke="#FFF" stroke-width="3" rx="36"/>
                <circle class="scroll" cx="36.5" cy="31.5" r="4.5" fill="#FFF"/>
            </g>
        </svg>
        <i class="fas fa-angle-down"></i>
    </a>
</div>


<section class="container-fluid container-principal">

    <div class="row text-center" id="proceso">
        <h1 class="titulos col-md-12">PROCESS</h1>
        <article class="col-lg-4 col-md-6">
            <div class="row">
                <div class="col-lg-12">
                    <img src="assets/img/1.png" class="img-fluid desktop" alt="" title="">
                    <img src="assets/img/1-mobile.png" class="img-fluid mobile" alt="" title="">
                </div>
                <div class="col-lg-12">
                    You will present a HR test and an English exam that will define if you are prepared to be part of PMX Academy
                </div>
            </div>
        </article>

        <article class="col-lg-4 col-md-6">
            <div class="row">
                <div class="col-lg-12">
                    <img src="assets/img/2.png" class="img-fluid desktop" alt="" title="">
                    <img src="assets/img/2-mobile.png" class="img-fluid mobile" alt="" title="">
                </div>
                <div class="col-lg-12">
                    If you passed the exam, you will have an interview with the program managers and an evaluation that will define if you are ready for 7 weeks of Digital Ads Operations Training
                </div>
            </div>
        </article>

        <article class="col-lg-4 col-md-6">
            <div class="row">
                <div class="col-lg-12">
                    <img src="assets/img/3.png" class="img-fluid desktop" alt="" title="">
                    <img src="assets/img/3-mobile.png" class="img-fluid mobile" alt="" title="">
                </div>
                <div class="col-lg-12">
                    Moreover, If you are one of the chosen to enter the program, you must deliver the necessary documents to HR and you will be prepared to start the program.
                </div>
            </div>
        </article>

        <article class="col-lg-4 col-md-6">
            <div class="row">
                <div class="col-lg-12">
                    <img src="assets/img/4.png" class="img-fluid desktop" alt="" title="">
                    <img src="assets/img/4-mobile.png" class="img-fluid mobile" alt="" title="">
                </div>
                <div class="col-lg-12">
                    Last, but not least, if you are one of the best in your course, you will be hired for an indefinite term and be part of the PMX team.
                </div>
            </div>
        </article>

        <article class="col-lg-4 col-md-6">
            <div class="row">
                <div class="col-lg-12">
                    <img src="assets/img/plus.png" class="img-fluid desktop" alt="" title="">
                    <img src="assets/img/plus-mobile.png" class="img-fluid mobile" alt="" title="">
                </div>
                <div class="col-lg-12">
                    You will receive a salary during the 7 weeks of the course digital ads operations.
                </div>
            </div>
        </article>

    </div>
</section>


<section class="container-full">
    <div class="container">
        <div class="row" id="about">
            <div class="col-lg-5 col-md-5">
                <h1 class="titulos text-white">About PMX Academy</h1>
                A powerful path to digital transformation.
                <br>Digital marketing is moving faster in all countries and Colombia is not far behind. We are immersed in the digital age, where we can connect with anyone from anywhere at any time regardless of distance.
                <br>Under this scenario, we decide to bet on new talents and design PMX Academy, a training program with advanced content of digital disciplines. Now you have the opportunity to be part of the most top talent, make a career in PMX and
                work with world-class clients.
                <br>If you speak English and have just graduated or are about to graduate, this opportunity is for you.
            </div>

            <div class="col-lg-7 col-md-7">
                <div class="container-formulario">
                    <div class="fondo-1-formulario"></div>
                    <div class="fondo-2-formulario"></div>
                    <div class="contorno-1-formulario"></div>
                    <form [formGroup]="profileForm" novalidate (ngSubmit)="onSubmit()">
                        <div class="row">
                            <h1 class="titulo-formulario col-lg-12">
                                COMPLETE THE FORM
                            </h1>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <h2 class="subtitulo-formulario col-lg-12"> Leave your contact details. </h2>

                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <input type="text" class="form-control" id="" aria-describedby="" placeholder="Full name*" formControlName="fullName">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="number" step="1" min="0" class="form-control" id="" aria-describedby="" placeholder="Age*" formControlName="age">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="phone" class="form-control" id="" aria-describedby="" placeholder="Mobile phone*" formControlName="phone">
                                    </div>
                                    <div class="form-group col-md-12">
                                        <input type="email" class="form-control" id="" aria-describedby="" placeholder="E-Mail*" formControlName="email">
                                    </div>
                                    <div class="form-group col-md-12">
                                        <select class="form-control" placeholder="English level" formControlName="englishLevel">
                                            <option value="" disabled>English Level*</option>
                                          <option value="A1">A1</option>
                                          <option value="A2">A2</option>
                                          <option value="B1">B1</option>
                                          <option value="B2">B2</option>
                                          <option value="C1">C1</option>
                                          <option value="C2">C2</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <select class="form-control" id="" formControlName="source">
                                            <option value="" disabled>Source*</option>
                                          <option value="linkedin">LinkedIn</option>
                                          <option value="university">My University</option>
                                          <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <select class="form-control" id="" formControlName="city">
                                        <option value="" disabled>City*</option>
                                        <option *ngFor="let city of cities" [ngValue]="city">{{ city.name }}</option>
                                          
        
                                        </select>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="">Upload your CV (optional)</label>
                                        <input type="file" class="form-control-file" id="file" formControlName="file">
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="" formControlName="legals">
                                        <label class="form-check-label" for="">
                                            I know and accept the <a href="http://www.ssccolombia.com/wp-content/uploads/2020/12/PL-01-Manual-Interno-de-Politicas-y-Procedimientos.pdf" target="_blank"> Personal Data Policies and Procedures Manual </a> and authorize the handling of these.
                                        </label>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <button type="submit" class="btn btn-primary" [disabled]="!profileForm.valid"> 
                            <div class="contorno-btn"></div>
                            APPLY
                            <div class="fondo1-btn"></div>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>