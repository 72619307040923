<header class="navbar-dark menu">
    <nav class="navbar navbar-expand-lg">

        <a class="navbar-brand" href="">
            <img src="assets/img/logo-pmx-academy.png" alt="Logo PMX Academy">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav my-2 my-lg-0">
                <li class="nav-item">
                    <a class="nav-link" href="#home">
                        HOME 
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="#proceso">
                        PROCESS
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="#about">
                        ABOUT US 
                    </a>
                </li>


            </ul>
        </div>
    </nav>
</header>