<mat-card class="login-card">
    <mat-card-title>Please Login</mat-card-title>
    <mat-card-content>
        <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()" class="form">
            <mat-form-field class="full-width">
                <mat-label>E-mail</mat-label>
                <input type="email" matInput placeholder="Tu e-mail" formControlName="email">
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>Password</mat-label>
                <input type="password" matInput placeholder="Ex. Pizza" formControlName="password">
            </mat-form-field>

            <button mat-stroked-button color="primary" type="submit" [disabled]="!loginForm.valid"> LOGIN</button>
        </form>
    </mat-card-content>
</mat-card>