import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private fs: AngularFirestore
  ) {

  }
  

  saveRecord( record: object ): Promise<any> {
    const id = this.fs.createId();
    return this.fs.doc( `academy_records/${ id }` ).set(record);
  }

  readRecords(): any {
    return this.fs.collection('academy_records', ref => ref.orderBy('ts')).valueChanges();
  }
}
